import { createI18n } from 'vue-i18n'
import en from '../locales/en'
import sv from '../locales/sv'

export const languages = { en, sv, nb: {}, pl: {}, it: {}, fr: {}, es: {}, da: {}, fi: {}, nl: {}, de: {}, pt: {}, ro: {}, uk: {}, hu: {}, ru: {}, sr: {}, ar: {}, ja: {}, 'zh-CN': {}, ko: {}, tr: {}, el: {}, th: {}, lt: {}, vi: {}, id: {}, lv: {}, et: {}, so: {}, cs: {}, hi: {}, 'zh-HK': {} };

export const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: en.messages,
        sv: sv.messages,
    },
    silentFallbackWarn: true,
});

export function setI18nLanguage(lang, setLangAttribute) {
    if (setLangAttribute) {
        document.querySelector('html').setAttribute('lang', lang);
    }
    i18n.global.locale = lang;
    return lang;
}

export async function loadLanguageAsync(lang) {
    // If the same language or the language file is already loaded
    if (i18n.global.locale === lang || (lang in languages && "messages" in languages[lang])) {
        return
    }

    // If the language hasn't been loaded yet
    return import(
        `../locales/${lang}.js`)
        .then(
            result => {
                let language = result.default;
                languages[lang] = language;
                i18n.global.setLocaleMessage(lang, language.messages)
                return
            },
        )
}
