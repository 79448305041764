<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import { ThemeHelper } from "./helpers/themeHelper";
import { i18n } from "./services/i18n.js"
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'app',
    i18n: i18n,
    components: {
        // Home,
    },
    beforeMount() {
        let userLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
        this.updateUserLanguageInitial(userLanguage);
    },
    computed: {
        ...mapGetters(["isLegalAccepted", "getCssTheme"]),
    },
    methods: {
        ...mapActions(["updateUserLanguageInitial", "themeIsLoading", "themeFinishedLoading"]),
    },
    watch: {
        isLegalAccepted(accepted){
            if(this.$gtm){ //Enable google tag manager, only avalible in prod
                this.$gtm.enable(accepted);
            }
        },
        getCssTheme(theme) {
            let themeHelper = new ThemeHelper();
            this.themeIsLoading();
            themeHelper.setTheme(`/css/${theme}`).finally(this.themeFinishedLoading);
        },
    },
}
</script>
<style lang="scss">
@import './styles/_forWebpack.scss';
body {
  background-color: rgba(0, 120, 214, 0.05);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
